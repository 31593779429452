import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import Heading from "../components/Heading";
import Card from "../components/Card";
import { Seo } from "../base/Seo";

import * as styles from "./index.module.scss";

export default function Index({ data }) {
  const posts = data.allMarkdownRemark.edges;

  return (
    <>
      <Layout>
        <Seo title="Home" description="Home Description" />

        <div className={`section ${styles.hero}`}>
          <div className="innerSection">
            <Heading tag="h2" className="heroLarge">
              Welcome to ResourceX. Curated free resources for your next
              project.
            </Heading>

            <Heading tag="h1" className="heroSub">
              ResourceX is a database of 100% free resources for web developers,
              designers, entrepreneurs to use for your personal/commercial
              projects.
            </Heading>
          </div>
        </div>

        <div className="section recent">
          <div className={styles.sectionHead}>
            <h3 className="sectionTitle">
              <a href="/">Trending</a>
            </h3>

            <Link to="/browse" className="more-link">
              See more
            </Link>

            <p className="section-sub">
              Popular (did I mention free) resources from the past 30 days
            </p>
          </div>

          <div className="card-listing">
            {posts.slice(0, 4).map((post) => {
              return <Card key={post.node.id} postNode={post.node} />;
            })}
          </div>
        </div>

        {/*<div className="section tags">
        <div className="sectionHead">
          <h3 className="sectionTitle">
            <a href="#">Popular tags</a>
          </h3>

          <p className="section-sub">
            Narrow your search to find exactly the resource you need
          </p>
        </div>

        <div className="card-listing">
          {sampleResources.slice(1, 5).map((resource) => {
            return (
              <div className="card">
                <h4 className="card-title">Cartoon Hands</h4>
                <div className="card-content">
                  <span>
                    by <a href="#">Lionel Taurus</a> in{" "}
                    <a href="#">Illustrations</a>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        </div> */}

        {/*<div className="section creators">
        <div className="sectionHead">
          <h3 className="sectionTitle">
            <a href="#">Meet the creators</a>
          </h3>

          <p className="section-sub">Follow the makers behind the free stuff</p>
        </div>

        <div className="card-listing">
          {sampleResources.slice(1, 5).map((resource) => {
            return <Card />;
          })}
        </div>
      </div>*/}
      </Layout>
    </>
  );
}

export const query = graphql`
  query HomePageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "single-post" } } }
      sort: { fields: frontmatter___publish_date, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            featured_img
            category
            authors
          }
        }
      }
    }
  }
`;
